import api from './api'
import * as Const from "@/config";


const client = api;

class TransferService {
    doError(error){
        if(error){
            console.log("IO-ERROR: " + error);
        }
    }

    get(path, params) {
        return client.get( path,
            {
                headers: {
                    ...{'withCredentials': 'true', 'Content-Type': 'multipart/form-data'},
            },
            params
        })
        .catch( error => {
            this.doError(error);
        })
        .then(response => {
            if(response != undefined && response.data != undefined){
                return new Promise(function (resolve){
                    resolve(response.data);
                });
            }
        });
    }

    put(path, json) {
        return client.put( path,
            json,
            {
                headers: {
                    ...{'withCredentials': 'true', 'Content-Type': 'application/json'},
                }
            }
        )
            .catch( error => {
                this.doError(error);
            })
            .then(response => {
                    return new Promise(function (resolve){
                        resolve(response.data);
                    });
                },
            );
    }

    getBinary(path, content) {
        if(content == undefined){
            content = 'application/zip';
        }
        return client.get( path,
            {
                responseType: 'arraybuffer',
                headers: {
                    ...{'withCredentials': 'true', 'Content-Type': content},
                }
            })
            .catch( error => {
                this.doError(error);
            })
            .then(response => {
                    return new Promise(function (resolve){
                        let name = "";
                        if(response.headers['content-disposition'] != undefined){
                            let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
                            let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
                            name = filename + "." + extension;
                            console.log(name)
                        }

                        if(response == undefined || response.data == undefined){resolve(response, name);}
                        else resolve(response.data, name);
                    });
                }
            );
    }


    postFile(path, json) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            // this will then display a text file
            return client.post( path,
                reader.result,
                {
                    headers: {
                        ...{'withCredentials': 'true', 'Content-Type': 'application/json'},
                        }
                    }
                )
                .catch( error => {
                    this.doError(error);
                })
                .then(response => {
                        return new Promise(function (resolve){
                            resolve(response.data);
                        });
                    },
                );
        }, false);

        return reader.readAsText(json);
    }



    post(path, json) {
        return client.post( path,
            json,
            {
                headers: {
                    ...{'withCredentials': 'true', 'Content-Type': 'application/json'},
                }
            }
        )
        .catch( error => {
            this.doError(error);
        })
        .then(response => {
                    if(response){
                        return new Promise(function (resolve){
                            resolve(response.data);
                        });
                    }
                },
            );
    }

    postId(path, id) {
        return client.post( path,
            { id: id },
            {
                headers: {
                    ...{'withCredentials': 'true', 'Content-Type': 'application/json'},
                }
            }
        )
            .catch( error => {
                this.doError(error);
            })
            .then(response => {
              return new Promise(function (resolve){
                if(response){
                  resolve(response.data);
                }
                else{
                  resolve(null);
                }
              });
              },
            );
    }

    putId(path, id) {
        return client.put( path,
            { id: id },
            {
                headers: {
                    ...{'withCredentials': 'true', 'Content-Type': 'application/json'},
                }
            }
        )
            .catch( error => {
                this.doError(error);
            })
            .then(response => {
                    return new Promise(function (resolve){
                        resolve(response.data);
                    });
                },
            );
    }

    postRow(path, row) {
        return client.post( path,
            row,
            {
                headers: {
                    ...{'withCredentials': 'true', 'Content-Type': 'application/json'},
                }
            }
        )
        .catch( error => {
            this.doError(error);
        })
        .then(response => {
                return new Promise(function (resolve){
                    resolve(response.data);
                });
            },
        );
    }

    postForm(path, file) {
        let formData = new FormData();
        formData.append('file', file);

        return client.post( path,
            formData,
            {
                headers: {
                    ...{'withCredentials': 'true', 'Content-Type': 'multipart/form-data'},
                }
            }
        )
        .catch( error => {
            this.doError(error);
        })
        .then(response => {
                return new Promise(function (resolve){
                    resolve(response.data);
                });
            },
        );
    }

    writeDomain(dom, file){
        return this.postForm( Const.EPLAN_DOMAIN_API + '/' + dom + '/upload', file).then(
            ok => {
                return Promise.resolve(ok);
            },
            error => {
                return Promise.reject(error);
            }
        )
    }
}



export default new TransferService();
