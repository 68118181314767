// Taken from:
// https://medium.com/@austinpaley32/how-to-add-a-constants-file-to-your-react-project-6ce31c015774
//
//  import * as Const from '../config';
//export const SERVER = 'http://localhost:80';
export const SERVER = location.hostname === 'localhost' ? 'http://localhost:8080' : location.origin;
//export const SERVER = 'https://acc.bkgk.de';
const SERVER_API = SERVER + '/api';

const AUTH_API = SERVER + '/auth';
export const AUTH_LOGIN = AUTH_API + '/login';
export const AUTH_LOGOUT = AUTH_API + '/logout';
export const AUTH_REFRESH = AUTH_API + '/access_token'

const ACC_API = SERVER_API + '/acc';
const READ_API = ACC_API + '/read';
export const READ_EXT_KLASSEN = READ_API + '/getextklassen';
export const READ_EXT_ACCOUNTS = READ_API + '/getextacc';
export const READ_MOODLE_ACCOUNTS = READ_API + '/getmoodle';
export const READ_MOODLE_JSON = READ_API + '/getmoodlejson';

const WRITE_API = ACC_API + '/write';
export const WRITE_PW = WRITE_API + '/newpasswort';
export const RESET_ALL_PW = WRITE_API + '/resetall';
export const UPDATE_ACCOUNT = WRITE_API + '/updaterow';
export const UPDATE_ALL_ACCOUNTS = WRITE_API + '/update';
export const UPDATE_KLASSEN = WRITE_API + '/updateklassen';
export const FIX_LDAP = WRITE_API + '/fixaccounts';
export const UPDATE_SELECTED_ACCOUNTS = WRITE_API + '/updateselected';
export const UPDATE_NEXTCLOUD = WRITE_API + '/updnextcloud';
export const COMPACT_ACCOUNTS = WRITE_API + '/compacc';
export const UPDATE_ROW_CHG = WRITE_API + '/updrowchg';
export const UPDATE_ROW_NEW = WRITE_API + '/updrownew';
export const UPDATE_ROW_DEL = WRITE_API + '/updrowdel';

export const READ_INT_ACCOUNTS = READ_API + '/getintacc';
export const LOAD_ACCOUNTS_FROM_FILE = WRITE_API + '/loadfromfile';
export const LOAD_EXT_ACCOUNTS = WRITE_API + '/loadext';
export const UPDATE_EXT_ACCOUNT = WRITE_API + '/updateextrow';
export const DEL_EXT_ACC_BY_ID = WRITE_API + '/delextid';
export const READ_DUPLICATES = READ_API + '/getduplicates';
export const READ_EXT_DUPLICATES = READ_API + '/checkextdup';
export const READ_PRINCIPAL = READ_API + '/getprincipal';
export const READ_OLD_ACCOUNTS = READ_API + '/getoldacc';
export const LOAD_OLD_FROM_FILE = WRITE_API + '/loadoldfromfile';
export const DEL_OLD_ACCOUNTS = WRITE_API + '/deloldacc';
// Role management
export const READ_ROLESETUP = READ_API + '/getrolesetup';
export const READ_ROLES = READ_API + '/getroles';
export const UPDATE_ROLES = WRITE_API + '/updroles';


const KONFIG_API = SERVER_API + '/konfig';
export const READ_KONFIG = KONFIG_API + '/read';
export const WRITE_KONFIG = KONFIG_API + '/write';

const ALL_API = SERVER_API + '/all';
export const BACKUP = ALL_API + '/backup';
export const FULLBACKUP = ALL_API + '/fullbackup';
export const BASEBACKUP = ALL_API + '/basebackup';

export const STATUS_API = SERVER_API + '/status';

export const EPLAN_API = SERVER_API +'/eplan';

export const READ_EPLAN_BEREICH = EPLAN_API + "/bereich";
export const READ_EPLAN_EXTRA = EPLAN_API + "/kukdiffs";
export const READ_EPLAN_BEREICHE = EPLAN_API + "/bereiche";
export const READ_EPLAN_SUMMEN = EPLAN_API + '/summen';
export const EPLAN_DOMAIN_API = EPLAN_API + '/domain';
export const EPLAN_ROW_UPDATE = EPLAN_API + '/update';
export const EPLAN_ROWTYPE_UPDATE = EPLAN_API + '/pastetype';
export const EPLAN_COPYUNTERR = EPLAN_API + '/copyu';
export const UGRUPPEN_API = EPLAN_DOMAIN_API + '/ugruppe';
export const READ_ANLAGEN_NAMEN = EPLAN_DOMAIN_API + '/anlagens';
export const READ_ANLAGE_API = EPLAN_DOMAIN_API + '/anlage';
export const WRITE_ANLAGE_API = EPLAN_DOMAIN_API + '/anlage';
export const READ_ANLAGEN_API = EPLAN_DOMAIN_API + '/anlagen';
export const WRITE_ANLAGEN_API = EPLAN_DOMAIN_API + '/anlagen';
export const DUP_ANLAGE = EPLAN_DOMAIN_API + '/anlagedup';
export const DEL_ANLAGE = EPLAN_DOMAIN_API + '/anlagedel';
export const WRITE_STDTAFEL = EPLAN_DOMAIN_API + '/stdntafel';
export const DEL_STDTAFEL = EPLAN_DOMAIN_API + '/stdntafeldel';
export const DUP_STDTAFEL = EPLAN_DOMAIN_API + '/stdntafeldup';
export const ANRECHNUNGEN_API = EPLAN_DOMAIN_API + '/anrechnungen';
export const ANRPIVOT_EPLAN = EPLAN_API + '/anrechnungpivot';
export const GPUUPLOAD_API = EPLAN_DOMAIN_API + '/gpuupload';
export const UNGROUP_EPLAN = EPLAN_API + '/ungroup';
export const GROUP_EPLAN = EPLAN_API + '/group';
export const KLASSE_EPLAN = EPLAN_API + '/klasse';
export const KLASSENBER_EPLAN = EPLAN_API + '/klassen';
export const KLASSESUMME_EPLAN = EPLAN_API + '/klassesummen';
export const USEDGRUPPEN_EPLAN = EPLAN_API + '/usedgruppen';
export const KLASSENGRUPPENCFG_EPLAN = EPLAN_API + '/klgruppen';
//
//  Domain standard api:
//      GET: base/config    get configuration
//      GET: base           get all data
//      POST: base          change one row
//      POST: base/delete   dekete one row identified by ID
//      POST: base/duplicate duplicate one row identified by ID
//
export const SJ_API = EPLAN_DOMAIN_API + '/schuljahr';
export const SJ_CLEAR_CHG = EPLAN_API + "/delchanges";
export const SJ_CLEAR_KOR = SJ_API + "/clearkorr";
export const SJ_LOAD_KOR = SJ_API + "/loadkorr";
export const SJ_LOAD_SJ = SJ_API + "/loadsj";
export const SJ_FIX = SJ_API + "/fix";


const VERT_API = SERVER_API + '/vert';
const UNTIS_VERT_API = VERT_API + '/untis';
export const READ_VERT_AUFGABEN = UNTIS_VERT_API + '/aufgaben';
export const WRITE_VERT_AUFGABEN = UNTIS_VERT_API + '/aufgaben';
export const READ_VERT_ABSENZEN = UNTIS_VERT_API + '/absenz';
export const READ_VERT_VERTRET = UNTIS_VERT_API + '/vertret';
export const READ_VERT_FREISETZ = UNTIS_VERT_API + '/freisetz';
export const READ_VERT_FREIPAAR = UNTIS_VERT_API + '/freisetzPaare';
export const READ_VERT_MAILLOG = UNTIS_VERT_API + '/log';
export const READ_VERT_STATISTIK = UNTIS_VERT_API + '/statistik';
export const READ_VERT_TESTSEND = UNTIS_VERT_API + '/sendReminder';
export const READ_VERT_DOSEND = UNTIS_VERT_API + '/doSendReminder';
export const READ_VERT_PLAN = UNTIS_VERT_API + '/stdpl';

const MAIL_API = VERT_API + '/mail';
export const READ_MAIL_STATUS = MAIL_API + '/status';
export const READ_MAIL_INIT = MAIL_API + '/init';
export const READ_MAIL_GROUPS = MAIL_API + '/getlists';
export const WRITE_MAIL_PLAENE = MAIL_API + '/plaene';
export const WRITE_MAIL_WEBDAV = MAIL_API + '/webdav';
export const WRITE_MAIL_WEBDAVINIT = MAIL_API + '/webdavinit';
export const WRITE_MAIL_SETPARAMS = MAIL_API + '/setparams';
export const WRITE_MAIL_TEMPLATE = MAIL_API + '/settemplate';
export const WRITE_MAIL_RECLIST = MAIL_API + '/setlist';
export const WRITE_MAIL_TEXT = MAIL_API + '/settext';
export const WRITE_MAIL_SENDLIST = MAIL_API + '/sendtolist';
export const WRITE_MAIL_SENDKUK = MAIL_API + '/sendtokuk';
export const WRITE_MAIL_SENDBIGAKO = MAIL_API + '/sendtobigako';
export const WRITE_MAIL_RESETVERT = MAIL_API + '/resetgpu';
export const WRITE_MAIL_SETGPU = MAIL_API + '/untis';

const TMPL_API = SERVER_API + '/tmpl';
export const READ_MAIL_TDIR = TMPL_API + '/dir';
export const READ_MAIL_TGET = TMPL_API + '/get';
export const MAIL_TMPL_DIR = TMPL_API + '/dir';
export const MAIL_TMPL_GET = TMPL_API + '/get';
export const MAIL_TMPL_SAVE = TMPL_API + '/post';
export const MAIL_TMPL_DEL = TMPL_API + '/del';

export const EPLAN_FLAG_MARKED = 0x00000001;

