// stores/schuljahre.js
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import TransferService from "@/services/transfer.service";
import * as Const from "@/config";
import {SJ_API} from "@/config";
import { consoleInfo } from 'vuetify/lib/util/console';

export const useSchuljahreStore = defineStore(
    'schuljahre',
    () => {
        const sj = ref(new Map());
        const selectedSJ = ref("")

        var initDone = false;

    //    init();
        function init() {
            return TransferService.get(Const.SJ_API).then(
                data => {
                    if(data){
                        var foundSelected = false;
                        var sel = '';
                        var cf = new Map();
                        for(const el of data){
                            cf.set(el.sj, el);
                            if(el.selected){
                                sel = el.sj;
                            }
                            if(selectedSJ.value === el.sj){
                                foundSelected = true;
                            }
                        }
                        sj.value = cf;
                        if(selectedSJ.value == undefined || !foundSelected){
                            selectedSJ.value = sel;
                        }
                        initDone = true;
                        return selectedSJ.value;
                    }
                },
                error => {
                    consoleInfo("Cannot get SJ info.");
                    return '';
                },
            );
        };

        function setDefaultSJ(sj, func){
            TransferService.postId(Const.SJ_API + '/default/'+ sj).then(function(){
                consoleInfo("Set default SJ to: "+ sj)
                if(func)
                    func(sj);
            });
        };

        function setSJ(sj, func){
            TransferService.postId(Const.SJ_API + '/current/'+ sj).then(function(){
                consoleInfo("Set current SJ to: "+ sj)
                if(func)
                    func(sj);
            });
            this.selectedSJ = sj;
        };

        function getFlags(){
          return this.sj.get(this.selectedSJ).readonly;
        };

        function getSchuljahre(){
          var res = [];
          this.sj.forEach((v,k,m) => {
            res.push(k);
          });
          return res;
        };

        return {sj, selectedSJ, setDefaultSJ, init, setSJ, getFlags, getSchuljahre};
    },
    {
        persist: true,
    },
);