//import Vue from 'vue';
import Router  from 'vue-router';
import Home from './views/acc/Home.vue';
import Login from './views/Login.vue';
import {useMenuStore} from '@/stores/menu';
import {createPinia} from "pinia";
import Vue from "vue";

//Vue.use(Router);

export const router = new Router({
  mode: 'hash',
//  mode: 'history',
  routes: [
    {
      path: '/',
      component: Login,
      meta: { guest: true, requiresAuth: false, requiresRoles:[]},
    },
    {
      path: '/login',
      component: Login,
      meta: { guest: true, requiresAuth: false, requiresRoles:[] },
    },
    {
      path: '/config',
      name: 'config',
      // lazy-loaded
      component: () => import('./views/acc/configstore.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_ADMIN']},
    },

    //
    //  Routes to accountmanagement
    //
    {
      path: '/acc/home',
      name: 'home',
      component: Home,
      meta: {requiresAuth: true, requiresRoles:['ROLE_TEACHER']},
    },
    {
      path: '/acc/allaccounts',
      name: 'allAccounts',
      // lazy-loaded
      component: () => import('./views/acc/allAccounts.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_TEACHER']},
    },
    {
      path: '/acc/moodle',
      name: 'moodle',
      // lazy-loaded
      component: () => import('./views/acc/moodle.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_ACCOUNTMANAGER']},
    },
    {
      path: '/acc/loaddata',
      name: 'loadData',
      // lazy-loaded
      component: () => import('./views/acc/LoadData.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_ACCOUNTMANAGER']},
    },
    {
      path: '/acc/checkdata',
      name: 'checkData',
      // lazy-loaded
      component: () => import('./views/acc/CheckData.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_ACCOUNTMANAGER']},
    },
    {
      path: '/acc/delrem',
      name: 'delRem',
      // lazy-loaded
      component: () => import('./views/acc/delrem.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_ACCOUNTMANAGER']},
    },
    {
      path: '/acc/editroles',
      name: 'editroles',
      // lazy-loaded
      component: () => import('./views/acc/EditRoles.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_ACCOUNTMANAGER']},
    },

    //
    //  Routes to eplan
    //
    {
      path: '/eplan/anrechnungen',
      name: 'anrechnungen',
      // lazy-loaded
      component: () => import('./views/eplan/anrechnungen.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },
    {
      path: '/eplan/anrechnungenpivot',
      name: 'anrechnungenpivot',
      // lazy-loaded
      component: () => import('./views/eplan/anrechnungenPivot.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },
    {
      path: '/eplan/ugruppen',
      name: 'ugruppen',
      // lazy-loaded
      component: () => import('./views/eplan/ugruppen.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },
    {
      path: '/eplan/stdtafeln',
      name: 'stdtafeln',
      // lazy-loaded
      component: () => import('./views/eplan/stdtafeln.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },
    {
      path: '/eplan/sj',
      name: 'schuljahre',
      // lazy-loaded
      component: () => import('./views/eplan/schuljahre.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },

    {
      path: '/eplan/kgroups/:bereich',
      name: 'klassengruppen',
      // lazy-loaded
      component: () => import('./views/eplan/klagroups.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },

    {
      path: '/eplan/eplan/:typ?/:elem?',
      name: 'eplansel',
      // lazy-loaded
      component: () => import('./views/eplan/eplanc.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },
    {
      path: '/eplan/lehrer/sum',
      name: 'kuksum',
      // lazy-loaded
      component: () => import('./views/eplan/kukpm.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },
    {
      path: '/eplan/lehrer/meta',
      name: 'kukmeta',
      // lazy-loaded
      component: () => import('./views/eplan/kukpm.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },
    {
      path: '/eplan/lehrer/stamm',
      name: 'kukstamm',
      // lazy-loaded
      component: () => import('./views/eplan/lehrer.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },
    {
      path: '/eplan/klassen/sum',
      name: 'klasum',
      // lazy-loaded
      component: () => import('./views/eplan/klapm.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },
    {
      path: '/eplan/klassen/stamm',
      name: 'klastamm',
      // lazy-loaded
      component: () => import('./views/eplan/klassen.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },
    {
      path: '/eplan/klassen',
      name: 'klassen',
      // lazy-loaded
      component: () => import('./views/eplan/klassen.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },
/*    {
      path: '/eplan/kplan/:bereich',
      name: 'kplan',
      // lazy-loaded
      component: () => import('./views/kplanc.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },*/
    {
      path: '/eplan/kplan/:typ/:elem?',
      name: 'kplanBereich',
      // lazy-loaded
      component: () => import('./views/eplan/kplanc.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_EPLAN']},
    },

    //
    //  Routes to vertretungen
    //
    {
      path: '/vert/vert/:val',
      name: 'Vertretungen',
      // lazy-loaded
      component: () => import('./views/vert/vertlist.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_VERTRETUNG']},
    },

    {
      path: '/vert/abw/:val',
      name: 'Abwesenheiten',
      // lazy-loaded
      component: () => import('./views/vert/abwlist.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_VERTRETUNG']},
    },

    {
      path: '/vert/anw/:val',
      name: 'anwesenheit',
      // lazy-loaded
      component: () => import('./views/vert/anwesen.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_VERTRETUNG']},
    },

    {
      path: '/vert/auf/:val',
      name: 'Aufgaben',
      // lazy-loaded
      component: () => import('./views/vert/aufgaben.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_VERTRETUNG']},
    },

    {
      path: '/vert/plaene/:zeit/:kuk?',
      name: 'Stundenplaene',
      // lazy-loaded
      component: () => import('./views/vert/planlist.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_VERTRETUNG']},
    },

    {
      path: '/vert/conf/conf',
      name: 'VertMailKonfig',
      // lazy-loaded
      component: () => import('./views/vert/vertconfig.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_VERTRETUNG']},
    },
    {
      path: '/vert/conf/templ',
      name: 'VertMailTemplates',
      // lazy-loaded
      component: () => import('./views/vert/vertconfig.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_VERTRETUNG']},
    },
    {
      path: '/vert/conf/file',
      name: 'VertMailKonfigFile',
      // lazy-loaded
      component: () => import('./views/configform.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_ADMIN']},
    },
    {
      path: '/vert/log',
      name: 'maillogv',
      // lazy-loaded
      component: () => import('./views/mailer/maillog.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_VERTRETUNG']},
    },

    //
    //  Routes to mailmanagement
    //
    {
      path: '/mail/log',
      name: 'maillogm',
      // lazy-loaded
      component: () => import('./views/mailer/maillog.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_MAILER']},
    },
    {
      path: '/mail/stdpl',
      name: 'mailstdpl',
      // lazy-loaded
      component: () => import('./views/mailer/maillog.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_MAILER']},
    },
    {
      path: '/mail/mailgrps',
      name: 'Maillisten',
      // lazy-loaded
      component: () => import('./views/mailer/mailgroups.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_MAILER']},
    },
    {
      path: '/mail/templ',
      name: 'Mailtemplates',
      // lazy-loaded
      component: () => import('./views/mailer/mailtempl.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_ADMIN']},
    },
    {
      path: '/mail/mailercfg',
      name: 'MailConfig',
      // lazy-loaded
      component: () => import('./views/mailer/mailconfig.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_ADMIN']},
    },
    {
      path: '/mail/stdplcfg',
      name: 'StdplConfig',
      // lazy-loaded
      component: () => import('./views/mailer/stdplanmail.vue'),
      meta: {requiresAuth: true, requiresRoles:['ROLE_ADMIN']},
    },
  ]
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user');
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (loggedIn) {
      let hasRole = false;
      let roles = JSON.parse(loggedIn).roles;
      if(to.meta.requiresRoles.length > 0 && roles.length > 0){
        for(var i = 0; i < roles.length; i++){
          hasRole = hasRole || to.meta.requiresRoles.includes(roles[i]);
        }
        if(hasRole){
          next();
          return;
        }
      }
    }
    next("/login");
  } else {
    next();
  }
});

/*
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    const loggedIn = localStorage.getItem('user');
    if (loggedIn) {
      next();
      return;
    }
    next();
  } else {
    next();
  }
});
*/

router.beforeEach((to, from, next) => {
  const menuStore = useMenuStore();
  menuStore.initRoute(to);
  next();
});

const DEFAULT_TITLE = 'BKEST Manager';
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});