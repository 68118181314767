import axios from "axios";
import * as Const from "@/config";
// from: https://www.bezkoder.com/vue-refresh-token/

const instance = axios.create({
  baseURL: Const.SERVER,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;